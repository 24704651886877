<template>
  <div class="form">
    <div v-for="(achievement, i) in achievements" :key="i">
      <h2 class="title-2">{{ achievement.name }}</h2>

      <div class="achievements">
        <div v-for="(objective, j) in achievement.objectives" :key="j"
          class="row achievement">
          <Input
            title="Objectif"
            type="text"
            v-model="objective.objective"
          />
          <InputFile
            title="Image"
            type="image"
            v-model="objective.image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import InputFile from '@/components/Forms/Fields/InputFile'

export default {
  name: 'AchievementsForm',
  components: {
    Input, InputFile
  },
  props: ['achievements']
}
</script>

<style lang="scss" scoped>

.achievements{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

</style>
