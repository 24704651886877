<template>
  <AdminView>
    <h1 class="title-1">Succès</h1>

    <AchievementsForm :achievements="achievements" />
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import AchievementsForm from '@/components/Forms/AchievementsForm'

export default {
  name: "Achievements",
  components: {
    AdminView, AchievementsForm
  },
  data () {
    return {
      achievements: {
        selfWorkoutSessions: {
          name: 'Séances d\'autorééducation',
          objectives: [
            {
              objective: 1,
              image: ''
            },
            {
              objective: 10,
              image: ''
            },
            {
              objective: 25,
              image: ''
            },
            {
              objective: 50,
              image: ''
            },
            {
              objective: 100,
              image: ''
            },
            {
              objective: 150,
              image: ''
            }
          ]
        },
        selfWorkoutStreak: {
          name: 'Séries d\'autorééducation (nombre de jours d\'affilé)',
          objectives: [
            {
              objective: 3,
              image: ''
            },
            {
              objective: 5,
              image: ''
            },
            {
              objective: 7,
              image: ''
            },
            {
              objective: 10,
              image: ''
            },
            {
              objective: 20,
              image: ''
            },
            {
              objective: 30,
              image: ''
            }
          ]
        },
        activitySessions: {
          name: 'Séances d\'activité',
          objectives: [
            {
              objective: 1,
              image: ''
            },
            {
              objective: 10,
              image: ''
            },
            {
              objective: 25,
              image: ''
            },
            {
              objective: 50,
              image: ''
            },
            {
              objective: 100,
              image: ''
            },
            {
              objective: 150,
              image: ''
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
